body {
  margin: 0;
  overflow-x: hidden;
}
*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.wrapper {
  width: 90vw;
  max-width: 1300px;
  margin: 0 auto;
}
a {
  text-decoration: none;
}
button {
  border: none;
}

/* #0c55E9
#FF0000
#565656  */
